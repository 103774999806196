import React, { lazy, Suspense } from 'react';
import jwt_decode from "jwt-decode";
import {
    Route,
    Redirect,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE, PRIVATE_ROUTE  } from './route.constants';
import Loader from '@iso/components/utility/loader';
import { useSelector } from 'react-redux';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: lazy(() => import('@iso/containers/SignIn/SignIn')),
    },
    {
        path: PUBLIC_ROUTE.SIGN_IN,
        exact: true,
        component: lazy(() => import('@iso/containers/SignIn/SignIn')),
    },
    {
        path: PUBLIC_ROUTE.FORGET_PASSWORD,
        component: lazy(() => import('@iso/containers/ForgotPassword/ForgotPassword')),
    },
    {
        path: PUBLIC_ROUTE.RESET_PASSWORD,
        component: lazy(() => import('@iso/containers/ResetPassword/ResetPassword')),
    },
    {
        path: PUBLIC_ROUTE.AUTH0_CALLBACK,
        component: lazy(() =>
            import('@iso/containers/Authentication/Auth0/Auth0Callback')
        ),
    },
];

const privateRoutes  =[
    {
        path: PRIVATE_ROUTE.archived,
        component: lazy(() =>
            import('@iso/containers/DossierArchived/DossierArchived')
        ),
    },
    {
        path: PRIVATE_ROUTE.clients,
        component: lazy(() =>
            import('@iso/containers/Clients/clients')
        ),
    },

]


function PrivateRoute({ children, ...rest }) {
    // let isLoggedIn = false
    // let decode = jwt_decode(localStorage.getItem('token'))
    // if(decode.exp > new Date().getTime() && localStorage.getItem('token') ) {
    //     isLoggedIn = true
    // }

    const isLoggedIn = localStorage.getItem('token');
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export default function Routes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, index) => (
                            <Route key={index} path={route.path} exact={route.exact}>
                                <route.component />
                            </Route>
                        ))}
                        <PrivateRoute path="/crm">
                            <Dashboard />
                        </PrivateRoute>
                    </Switch>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
}
