export const PUBLIC_ROUTE = {
    LANDING: '/',
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    FORGET_PASSWORD: '/forgotpassword',
    RESET_PASSWORD: '/resetpassword/:user_id/:token',
    PAGE_404: '/404',
    PAGE_500: '/500',
    AUTH0_CALLBACK: '/auth0loginCallback',
};

export const PRIVATE_ROUTE = {
    crm: '/crm',
    register: '/register',
    clients: 'clients',
    interventions: 'client/interventions/:client_id',
    client_info: 'client-info',
    client_affected: 'client-affected',
    client_affected_details: 'client_affected/details/:client_id',
    sub_informations: 'sub-informations',
    attrubution_Chirurgiens: 'attrubution-Chirurgiens',
    in_Traitement: 'in-Traitement',
    diagnostic: 'diagnostic',
    management: 'management/setting',
    add_doctors: 'doctors/add',
    management_staff_doctor: 'crm/management/staff/doctors',
    cliniques_add: 'cliniques/add',
    currency_add: 'currency/add',
    hotels_add: 'hotels/add',
    interventions_add: 'interventions/add',
    operators_add: 'operators/add',
    analyses_add: 'analyses/add',
    doctors_update: 'doctors/update/:doctor_id',
    cliniques_update: 'cliniques/update/:clinique_id',
    currency_update: 'currency/update/:currency_id',
    hotels_update: 'hotels/update/:hotel_id',
    interventions_update: 'interventions/update/:intervention_id',
    operators_update: 'operators/update/:operator_id',
    analyses_update: 'analyses/update/:analyse_id',
    management_staff: 'management/staff',
    management_users: 'crm/management/staff/users',
    user_add: 'user/add',
    user_update: 'user/update/:user_id',
    client_dossiers: 'client/client-dossiers/:client_id',
    add_client: 'add-client',
    dossiers: 'dossiers',
    archived: 'archived',

};
